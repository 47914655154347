.empty-notifications {
  display: flex;
  width: 100%;
  margin: 1rem 0 0;
  flex-flow: column; }
  .empty-notifications__image {
    margin: 5rem auto;
    width: 226px;
    height: 206px; }
  .empty-notifications p {
    margin: 0 auto;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: center; }
