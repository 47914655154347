body .redux-toastr .top-left,
body .redux-toastr .top-right,
body .redux-toastr .top-center,
body .redux-toastr .bottom-left,
body .redux-toastr .bottom-right,
body .redux-toastr .bottom-center {
  padding: 0;
  width: 100%; }

body .redux-toastr .bottom-left {
  display: flex;
  flex-direction: column-reverse; }

body .redux-toastr .toastr {
  border-radius: 0;
  margin: 0 !important;
  display: flex;
  align-items: center;
  width: 100%; }
  body .redux-toastr .toastr:hover {
    box-shadow: none !important; }
  body .redux-toastr .toastr > div {
    width: 100%; }
  body .redux-toastr .toastr .rrt-middle-container {
    padding-top: 10px;
    font-size: 1.8em;
    width: 85%; }
    @media (max-width: 768px) {
      body .redux-toastr .toastr .rrt-middle-container {
        max-width: calc(100% - 40px);
        margin-left: 0 !important;
        font-size: 1.6rem !important; } }
    @media (max-width: 768px) {
      body .redux-toastr .toastr .rrt-middle-container {
        font-size: 1.4rem !important; } }
  body .redux-toastr .toastr .close-toastr {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    font-size: 0.9rem;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -khtml-border-radius: 20px; }
  @media (max-width: 640px) {
    body .redux-toastr .toastr.toastr--cookies .rrt-middle-container, body .redux-toastr .toastr.goal-details .rrt-middle-container {
      max-width: 100%;
      width: 100%; } }
