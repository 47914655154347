.button {
  border: 0;
  cursor: pointer;
  font-size: 1.6rem;
  height: 4rem;
  width: fit-content;
  transition: 0.3s ease-out; }
  .button:focus {
    outline: 0; }
  .button--primary {
    border-bottom-width: 3px;
    padding: 1px 15px; }
  .button--secondary {
    border-bottom-width: 3px;
    padding: 1px 15px; }
  .button--secondary.button--less-padding {
    padding: 1px 10px; }
  .button--half-left {
    font-size: 1.6rem;
    min-width: 50px; }
  .button--half-right {
    font-size: 1.6rem;
    min-width: 50px; }
  .button--facebook {
    height: 4.4rem;
    margin-bottom: 1.5rem !important; }
  .button--apple {
    display: flex;
    align-items: center;
    height: 4.4rem;
    justify-content: center; }
  .button--auto-size {
    width: auto; }
  @media (min-width: 1200px) {
    .button--medium-large-size {
      min-width: 240px;
      font-size: 1.6rem; }
    .button--medium-size {
      min-width: 200px; }
    .button--small-size {
      min-width: 160px; }
    .button--smaller-size {
      min-width: 140px; } }
  .button--medium-large-size {
    min-width: 200px;
    font-size: 1.4rem; }
  .button--medium-size {
    min-width: 160px; }
  .button--small-size {
    min-width: 130px; }
  .button--smaller-size {
    min-width: 100px; }
  .button--half-size {
    min-width: 50%; }
  .button--full-size {
    min-width: 100%; }
  .button--margin-bottom {
    margin-bottom: 30px; }
  .button--large {
    min-width: 16rem; }
  .button--small-font {
    font-size: 1.6rem; }
  .button--large-height {
    height: 4rem; }
  .button--larger-height {
    height: 6.4rem; }
  .button--center {
    margin-left: auto;
    margin-right: auto; }
  .button--login {
    min-width: 10rem; }
  .button--inline {
    padding: 0; }
