/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after {
  content: '';
  content: none; }

q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.freshwidget-button .freshwidget-theme {
  font-size: 2rem;
  line-height: 3.6rem;
  transition: 0.2s ease-out; }

@media (max-width: 1200px) {
  .freshwidget-button {
    top: 88px !important;
    position: absolute !important; }
    .freshwidget-button .freshwidget-theme {
      line-height: 2.6rem;
      font-size: 1.6rem;
      transform: rotate(0) !important;
      left: 15px;
      position: relative !important; } }

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem; }
  .hide-on-mobile {
    display: none; }
  .right-container.full-w-on-mobile,
  .full-w-on-mobile {
    width: 100%; } }
