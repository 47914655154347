.browser-warning-banner {
  display: block;
  width: 100%;
  height: 6.8rem; }
  .browser-warning-banner__content {
    margin: 0 auto;
    z-index: 1001;
    line-height: 2.6rem;
    font-size: 1.8rem;
    padding: 10px 30px;
    position: relative; }
    @media (min-width: 768px) {
      .browser-warning-banner__content {
        width: 1010px; } }
    @media (min-width: 992px) {
      .browser-warning-banner__content {
        width: 1010px; } }
    @media (min-width: 1200px) {
      .browser-warning-banner__content {
        width: 1010px; } }
  .browser-warning-banner__dismiss {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 27%;
    right: 0px;
    font-size: 0.9rem;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 10px; }
  .browser-warning-banner__icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 27%;
    left: 0px;
    background-size: contain;
    background-repeat: no-repeat; }
