.maintenance {
  display: flex;
  width: 100%;
  margin: auto;
  flex-flow: column; }
  .maintenance__title {
    margin: 12rem 0 0;
    text-align: center; }
  .maintenance__detail {
    text-align: center; }
  .maintenance__retry {
    text-align: center;
    margin: 3rem auto 0; }
  .maintenance__image {
    width: 65rem;
    height: 29.7rem;
    margin: 5rem auto 9rem; }
