.toastr--coachmark .rrt-left-container,
.toastr--coachmark .rrt-right-container {
  width: 0 !important; }

.toastr--coachmark .rrt-middle-container {
  margin: 0 auto !important;
  float: none !important; }
  @media (min-width: 1200px) {
    .toastr--coachmark .rrt-middle-container {
      width: 710px !important; } }

.toastr.goal-details .rrt-middle-container {
  width: 100% !important;
  padding: 10px 5px 0 !important; }
  @media (min-width: 1200px) {
    .toastr.goal-details .rrt-middle-container {
      width: 940px !important; } }

.toastr.goal-details .coachmark {
  display: grid;
  grid-template-columns: 200px auto; }
  .toastr.goal-details .coachmark__icon {
    width: 160px;
    min-width: 160px;
    height: 160px;
    margin-right: 4rem;
    grid-row-start: 1;
    grid-row-end: span 2; }
  .toastr.goal-details .coachmark__text {
    margin-bottom: 20px; }
  .toastr.goal-details .coachmark__actions {
    margin-left: 0rem;
    flex: 0 0 calc(100% - 200px); }
    .toastr.goal-details .coachmark__actions .button--primary {
      min-width: 200px; }
  .toastr.goal-details .coachmark__title {
    margin-right: 0px; }
  .toastr.goal-details .coachmark--mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .toastr.goal-details .coachmark--mobile .coachmark__icon {
      margin-bottom: 2rem;
      margin-right: 0;
      width: 100px;
      min-width: 100px;
      height: 100px; }
    .toastr.goal-details .coachmark--mobile .coachmark__title {
      margin-top: 0; }

.coachmark {
  display: flex;
  flex-direction: row;
  padding: 15px 10px 20px 10px; }
  .coachmark__title {
    margin-right: 12px;
    margin-bottom: 7px;
    margin-top: -5px; }
  .coachmark__body {
    line-height: 2.5rem; }
  .coachmark__icon {
    width: 36px;
    min-width: 36px;
    height: 36px;
    margin-right: 1.5rem; }
  .coachmark__actions {
    margin-left: 1.5rem; }
