.private .mobile__navigation .profile-menu {
  flex-direction: row;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .private .mobile__navigation .profile-menu__user-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden; }
  .private .mobile__navigation .profile-menu__user-email {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    margin-bottom: 5px; }
  .private .mobile__navigation .profile-menu__link {
    margin-bottom: 5px; }
