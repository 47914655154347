.mixed .header__container {
  align-items: center;
  display: flex;
  margin: 20px auto;
  padding-left: 15px;
  padding-right: 15px; }
  .mixed .header__container > a {
    text-decoration: none;
    display: flex;
    align-items: center; }
  @media (min-width: 768px) {
    .mixed .header__container {
      width: 750px; } }
  @media (min-width: 992px) {
    .mixed .header__container {
      width: 970px; } }
  @media (min-width: 1200px) {
    .mixed .header__container {
      width: 1170px; } }
  .mixed .header__container .create-icon {
    background-size: 24px 24px;
    background-repeat: no-repeat;
    padding-left: 24px; }

.mixed .header__navigation {
  display: flex;
  margin-left: auto; }
  .mixed .header__navigation button {
    font-size: 1.4rem;
    height: 3.8rem; }

.mixed .header__navigation-list {
  display: flex;
  list-style: none;
  align-items: center; }
  .mixed .header__navigation-list-item {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    margin-right: 35px;
    line-height: 36px; }
    .mixed .header__navigation-list-item a {
      text-decoration: none; }
      .mixed .header__navigation-list-item a:hover {
        text-decoration: underline; }
    .mixed .header__navigation-list-item:last-child {
      margin-right: 0; }
  @media (max-width: 640px) {
    .mixed .header__navigation-list {
      display: none; } }

.mixed .header__language-selector {
  margin-left: 35px;
  align-items: center;
  display: flex; }

.mixed .header__logo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-flex;
  font-size: 42px;
  height: 42px;
  margin: 0;
  width: 63px; }

.mixed .header__text {
  font-size: 16px;
  line-height: 45px;
  margin-left: 14px; }

.content {
  width: 100%;
  margin: 0px auto; }
  .content--private {
    max-width: 940px; }

.mixed.footer {
  border: none; }
  @media (min-width: 1200px) {
    .mixed.footer {
      width: 940px !important; } }
