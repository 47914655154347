.notifications-menu {
  width: 26px;
  height: 26px;
  position: relative;
  cursor: pointer; }
  .notifications-menu__count {
    position: absolute;
    line-height: 1.7rem;
    height: 17px;
    min-width: 17px;
    top: -5px;
    right: -10px;
    text-align: center; }
